.container {
  max-width: 100vw;
  max-height: 100vh;
  padding: 40px;
}

.gridCell {
  width: 50px;
  aspect-ratio: 1;
  text-align: center;
  line-height: 50px;
  margin: 2px;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.gridCell:hover {
  filter: brightness(85%);
  -webkit-filter: brightness(85%);
}

.gridCol {
  padding: 0;
  max-width: fit-content;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

/* Optional: Adjust the grid layout to accommodate the new row number cell */
.gridCol {
  padding: 0;
  max-width: fit-content;
}

.gridRow {
  display: flex;
  flex-direction: row; /* Change to column if needed */
  justify-content: space-between;
  align-items: center;
}

/* Add this CSS to your Home.module.css */
.rowNumberCell,
.columnNumberCell {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Apply ghosted blue background to the first cell in the hovered cell's row and column */
.hoverEffect {
  background-color: #e0e0f0; /* Ghosted blue color */
}

.selectionsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.selectedCellDetails,
.randomlySelectedCellDetails {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: fit-content;
  min-width: 300px;
  background-color: #f0f0f0;
}

.randomlySelectedCellDetails {
  margin-left: 20px;
  /* display: none; */
}

.chooseCellDialog {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.introSection {
  text-align: center;
  background-color: #035e61;
  padding: 30px;
}

.introSectionTitle {
  color: white;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 3rem;
}

.userOptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 45%;
  padding: 0;
}

body {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  background-color: black !important;
}

#root {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
}

.main {
  min-height: 100vh;
  min-width: 100%;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  background-color: #feffce;
  background-color: black;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  position: relative;
}

.intro {
  background-color: #0e6063;
  margin: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  color: white;
  font-size: 40px;
  text-transform: uppercase;
  letter-spacing: 4px;
  user-select: none;
}

.game {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  flex-wrap: wrap;
}

.chooseCellTitle {
  color: lightgray;
  user-select: none;
  align-self: center;
  text-align: center;
  margin: 0px;
}

.userCell {
  color: lightgray;
  padding: 0px;
  padding-bottom: 2vh;
  margin: 0px;
  text-align: center;
  text-transform: capitalize;
}

.userChooseCell.show {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sure {
  color: lightgray;
  text-transform: capitalize;
}

.selectButton {
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  border: none;
  margin: 0px 10px;
  padding: 5px 10px;
  min-width: 100px;
  filter: brightness(82%);
  -webkit-filter: brightness(82%);
  color: white;
  font-weight: bold;
}

.selectButton:hover {
  filter: brightness(100%);
  -webkit-filter: brightness(100%);
}

.selectButton.yes {
  background-color: #339563;
}

.selectButton.no {
  background-color: #d81f58;
}

.userRow,
.userCol,
.userColor,
.userIcon {
  color: lightgray;
}

.show {
  display: block;
}

.hide {
  display: none !important;
}

.userOptions,
.userSelect {
  margin: 0;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
}

.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 30px;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.gridRow {
  margin: 0;
  padding: 0;
  justify-content: center;
  flex-wrap: nowrap;
}

.gridColumn {
  border-radius: 10px;
  min-height: 4vw;
  min-width: 4vw;
  width: 4vw;
  height: 4vw;
  max-width: 4vw;
  margin: 2px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: black;
  filter: brightness(85%);
  -webkit-filter: brightness(85%);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.gridColumn:hover {
  filter: brightness(145%) !important;
  -webkit-filter: brightness(145%) !important;
}

.gridColumn > p {
  color: white;
  margin: 0;
  pointer-events: none;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.highlight {
  filter: brightness(115%) !important;
  -webkit-filter: brightness(115%) !important;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  border: 2px solid green;
}

.gridColumn[rowindex="0"].indexHighlight:hover,
.gridColumn[colindex="0"].indexHighlight:hover {
  background-color: transparent !important;
}

.icon {
  width: 75%;
  height: 75%;
  pointer-events: none;
}

.colorTransparent,
.iconTransparent,
.rowTransparent,
.colTransparent,
.userTransparent {
  background-color: transparent !important;
  color: transparent !important;
  border: 1px solid rgb(143, 143, 143);
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
  filter: brightness(82%);
  -webkit-filter: brightness(82%);
}

.colorTransparent:hover,
.iconTransparent:hover,
.rowTransparent:hover,
.colTransparent:hover,
.userTransparent:hover,
.colorTransparent.highlight,
.iconTransparent.highlight,
.rowTransparent.highlight,
.colTransparent.highlight,
.userTransparent.highlight {
  filter: brightness(82%);
  -webkit-filter: brightness(82%);
}

.colorTransparent > p,
.iconTransparent > p,
.rowTransparent > p,
.colTransparent > p,
.userTransparent > p {
  background-color: transparent !important;
  border: none;
}

.colorTransparent[rowindex="0"],
.iconTransparent[rowindex="0"],
.rowTransparent[rowindex="0"],
.colTransparent[rowindex="0"],
.userTransparent[rowindex="0"],
.colorTransparent[colindex="0"],
.iconTransparent[colindex="0"],
.rowTransparent[colindex="0"],
.colTransparent[colindex="0"],
.userTransparent[colindex="0"] {
  background-color: transparent !important;
  border: none;
}

.colorTitle,
.iconTitle,
.rowTitle,
.colTitle {
  align-self: flex-start;
  color: lightgray;
  user-select: none;
}

.colorButtons,
.iconButtons,
.rowButtons,
.colButtons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  background-color: rgb(143, 143, 143);
  margin: 30px;
  margin-top: 0px;
  margin-bottom: 2vh;
  padding: 2vh;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.colorButton,
.iconButton,
.colButton,
.rowButton {
  padding: 6px;
  width: 100px;
  margin: 6px;
  text-align: center;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  border: 2px solid black;
  user-select: none;
  color: black;
}

.colorButton,
.iconButton,
.colButton,
.rowButton {
  background-color: lightgray !important;
}

.colorButton:hover,
.iconButton:hover,
.colButton:hover,
.rowButton:hover {
  border: 2px solid rgb(197, 196, 196);
  filter: brightness(150%);
  -webkit-filter: brightness(150%);
}

.iconButton {
  width: 80px;
  height: 80px;
  border: none;
  border: 2px solid black;
}

.rowButton,
.colButton {
  width: 50px;
  height: 50px;
}

.disabled {
  border: 2px solid rgb(197, 196, 196);
  background-color: transparent !important;
  color: rgb(197, 196, 196) !important;
}

.disabled:hover {
  filter: brightness(150%);
  -webkit-filter: brightness(150%);
  background-color: transparent !important;
}

.buttonHighlight {
  border: 2px solid green !important;
  background-color: rgb(104, 202, 104) !important;
}

.buttonHighlight[colorname="blue"] {
  background-color: #099cda !important;
}

.buttonHighlight[colorname="pink"] {
  background-color: #d87dad !important;
}

.buttonHighlight[colorname="green"] {
  background-color: #339563 !important;
}

.buttonHighlight[colorname="brown"] {
  background-color: #975e36 !important;
}

.buttonHighlight[colorname="silver"] {
  background-color: #8998a6 !important;
}

.buttonHighlight[colorname="purple"] {
  background-color: #754494 !important;
}

.buttonHighlight[colorname="red"] {
  background-color: #d81f58 !important;
}

.buttonHighlight[colorname="orange"] {
  background-color: #d26651 !important;
}

.buttonHighlight[colorname="yellow"] {
  background-color: #ddcd52 !important;
}

.hoverState {
  filter: brightness(130%) !important;
  -webkit-filter: brightness(130%) !important;
}

@media screen and (min-width: 1920px) {
  .game {
    flex-grow: 1;
  }

  .userOptions {
    width: 50%;
  }

  .gridColumn {
    min-height: 2vw;
    min-width: 2vw;
    width: 2vw;
    height: 2vw;
    max-width: 2vw;
  }

  .userSelect {
    width: auto;
  }
}

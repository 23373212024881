.columnButtonsWrapper {
  background-color: rgb(182, 214, 221);
  padding: 30px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin-top: 20px;
}

.columnButtonsTitle {
}

.columnButtons {
  border-radius: 999px;
  min-width: 80px;
  margin: 5px;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px;
}

.columnButtons:hover {
  filter: brightness(85%);
}
